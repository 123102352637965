import {deepClone} from '@/tools'

export default class Audio {
	// 是否为暂停状态
	isPause = true
	// 是否为静音状态
	isMute = false
	// 音量大小
	volume = 60
	// 播放类型 1顺序播放、2随机播放、3单曲循环、4循环播放
	playType = 1
	// 播放列表数据
	playList = []
	// 当前歌曲信息
	musicInfo = {};

	constructor() {
		this.checkLocalDataInfo();
	}

	/**
	 * @description: 初始化后检测本地数据缓存信息
	 */
	checkLocalDataInfo() {
		this.checkLocalPlayList();
		this.checkLocalPlayInfo();
		this.checkLocalVolumeInfo();
	}

	/**
	 * @description: 检测本地的播放清单缓存
	 */
	checkLocalPlayList() {
		const list = localStorage.getItem('playList');
		this.playList = JSON.parse(list);
	}

	/**
	 * @description: 检测本地的播放歌曲缓存
	 */
	checkLocalPlayInfo() {
		const info = localStorage.getItem('playInfo');
		this.setPlayMusicInfo(JSON.parse(info));
	}

	/**
	 * @description: 检测本地音量信息
	 */
	checkLocalVolumeInfo() {
		const volume = localStorage.getItem('volume');
		if (volume) {
			this.volume = parseInt(volume);
		}
	}

	/**
	 * @description: 设置是否为暂停状态
	 */
	setIsPauseStatus() {
		this.isPause = !this.isPause
	}

	/**
	 * @description: 获取是否为暂停状态
	 */
	getIsPauseStatus() {
		return this.isPause
	}

	/**
	 * @description: 设置静音状态
	 */
	setIsMuteStatus() {
		this.isMute = !this.isMute
	}

	/**
	 * @description: 获取静音状态
	 */
	getIsMuteStatus() {
		return this.isMute
	}

	/**
	 * @description: 设置音量大小
	 */
	setVolumeData(volume) {
		this.volume = volume
	}

	/**
	 * @description: 获取音量大小
	 */
	getVolumeData() {
		return this.volume
	}

	/**
	 * @description: 设置播放类型
	 */
	setPlayerType() {
		this.playType += 1
		this.playType = this.playType > 4 ? 1 : this.playType
	}

	/**
	 * @description: 获取播放类型
	 */
	getPlayType() {
		return this.playType
	}

	/**
	 * @description: 设置播放列表数据
	 */
	setPlayList(list = []) {
		this.playList = list
		localStorage.setItem('playList', JSON.stringify(list))
		this.setPlayMusicInfo(list[0]);
	}

	/**
	 * @description: 获取播放列表数据
	 */
	getPlayList() {
		return deepClone(this.playList)
	}

	/**
	 * @description: 设置播放的歌曲信息
	 */
	setPlayMusicInfo(dataInfo = {}) {
		if (!dataInfo?.name) {
			return;
		}
		const {name, singerInfo} = dataInfo;
		const {name: sName} = singerInfo;
		this.musicInfo = dataInfo
		document.title = `${name} - ${sName}`;
		localStorage.setItem('playInfo', JSON.stringify(dataInfo));
	}

	/**
	 * @description: 获取播放的歌曲信息
	 */
	getPlayMusicInfo() {
		return deepClone(this.musicInfo);
	}
}
